<template>
  <div>
    <div class="lead-search-area">
      <div class="row">
        <div class="col-md-3">
          <div class="lead-search">
            <form action="">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Vehicle</label>
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="filterSearch.vehicle"
                  v-on:change="updateData('vehicle')"
                >
                  <option value="new">New</option>
                  <option value="used">Used</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-3">
          <div class="lead-search">
            <form action="">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Deal Type</label>

                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="filterSearch.desking_type"
                  v-on:change="updateData('desking_type')"
                >
                  <option value="lease">Lease</option>
                  <option value="finance">Finance</option>
                  <option value="deal_type">Cash</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-3">
          <div class="lead-search">
            <form action="">
              <div class="form-group">
                <label for="exampleFormControlSelect1">In Store</label>

                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="filterSearch.currenttlyinstore"
                  v-on:change="updateData('currenttlyinstore')"
                >
                  <option value="0">Currently in store</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-3">
          <div class="lead-search">
            <form action="">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Agent</label>
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="filterSearch.agent"
                  v-on:change="updateData('agent')"
                >
                  <option value="">Select Agent</option>
                  <option
                    v-for="emp in allEmployees"
                    :key="emp.id"
                    :value="emp.id"
                  >
                    {{ emp.name }}
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- lead-search -->

    <!-- leads-table-area  -->
    <div class="leads-table-area task-table">
      <div class="timeline">
        <button
          type="button"
          v-bind:class="filterSearch.duration == 'today' ? 'current' : ''"
          v-on:click="updateData('today')"
        >
          <span class="today">Today</span>
        </button>
        <button
          type="button"
          v-bind:class="filterSearch.duration == 'week' ? 'current' : ''"
          v-on:click="updateData('week')"
        >
          <span class="today">Week</span>
        </button>
        <button
          type="button"
          v-bind:class="filterSearch.duration == 'yesterday' ? 'current' : ''"
          v-on:click="updateData('yesterday')"
        >
          <span class="today">Yesterday</span>
        </button>
        <button
          type="button"
          v-bind:class="filterSearch.duration == 'month' ? 'current' : ''"
          v-on:click="updateData('month')"
        >
          <span class="today">This Month</span>
        </button>
        <button
          type="button"
          v-bind:class="filterSearch.duration == 'custom' ? 'current' : ''"
        >
          <date-range-picker
            :opens="'left'"
            :ranges="false"
            :locale-data="{ format: 'yyyy-mm-dd' }"
            v-model="filterSearch.customDate"
            @update="updateData('custom')"
          ></date-range-picker
          ><span class="month">Custom Date</span>
        </button>
      </div>

      <table class="display" id="tasks_table">
        <thead>
          <tr>
            <th>Lead ID</th>
            <th>Customer</th>
            <th>Agent</th>
            <th>Type</th>
            <th>Currently In Store</th>
            <th>Vehicle</th>
            <th>Sales Type</th>
            <th>Credit</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- leads-table-area  -->
    <!-- appointment  deatils Modal -->
    <div
      class="modal fade"
      id="tasktDetail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-12">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Description</td>
                        <td v-html="task_detail.a_desc"></td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>{{ task_detail.a_date }}</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>{{ task_detail.a_time }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled By</td>
                        <td>{{ task_detail.a_by }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled For</td>
                        <td>{{ task_detail.a_for }}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{{ task_detail.a_status }}</td>
                      </tr>
                      <tr>
                        <td>Lead Id</td>
                        <td>
                          <a
                            href="#"
                            v-on:click.stop.prevent="
                              redirectIt(task_detail.ta_lead_id)
                            "
                          >
                            {{ task_detail.ta_lead_id }}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Created Date</td>
                        <td>{{ task_detail.created_date }}</td>
                      </tr>
                      <br />
                      <div class="row gotolead">
                        <a
                          href="#"
                          v-on:click.stop.prevent="
                            redirectIt(task_detail.ta_lead_id)
                          "
                          class="rem-btn"
                          ><span> Go to lead</span></a
                        >
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- <div class="col-12 text-center mt-3">
                <button class="rem-btn" style="display: initial">Select</button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- appointment  deatils Modal  -->
  </div>
</template>


<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      tasks: null,
      filterSearch: {
        duration: "today",
        vehicle: "",
        team: "",
        customDate: {},
        from: "",
        to: "",
        desking_type: "",
        currenttlyinstore: "",
      },
      task_detail: {},
      allEmployees: [],
      allTeams: [],
    };
  },
  created() {
    this.getAllEmployees();
  },
  methods: {
    getAllEmployees() {
      axios
        .get("all_employees", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.allEmployees = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    redirectIt(id) {
      $("#tasktDetail").modal("hide");
      setTimeout(() => {
        this.$router.push("/desking/" + id);
      }, 400);
    },
    updateData(val) {
      if (val == "vehicle") {
        this.filterSearch.team = "";
      } else if (val == "team") {
        this.filterSearch.agent = "";
      } else if (val == "desking_type") {
        this.filterSearch.agent = "";
      } else if (val == "currenttlyinstore") {
        this.filterSearch.team = "";
      } else if (val == "agent") {
        this.filterSearch.team = "";
      } else if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.filterSearch.from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.filterSearch.to = moment(new Date(endDate)).format("YYYY-MM-DD");
        this.filterSearch.duration = "custom";
      } else {
        this.filterSearch.duration = val;
      }
      $("#tasks_table").dataTable().fnDraw();
    },
  },
  // watch: {
  //     "$route.query.type": function () {
  //         this.updateData()
  //     },
  //     "filterSearch.keyword": function() {
  //         this.updateData()
  //     },

  // },
  mounted() {
    let v = this;
    $("#tasks_table")
      .addClass("nowrap")
      .dataTable({
        language: {
          lengthMenu: "Display _MENU_ records per page",
          zeroRecords: "Nothing found - sorry",
          info: "Showing page _PAGE_ of _PAGES_",
          infoEmpty: "No records available",
          infoFiltered: "",
        },
        order: [[2, "desc"]],
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: axios.defaults.baseURL + "desking/log",
          type: "POST",
          data: function (d) {
            d.agent = v.filterSearch.agent;
            d.tm_id = v.filterSearch.team;
            d.duration = v.filterSearch.duration;
            d.from = v.filterSearch.from;
            d.to = v.filterSearch.to;
            d.vehicle = v.filterSearch.vehicle;
            d.desking_type = v.filterSearch.desking_type;
            d.currenttlyinstore = v.filterSearch.currenttlyinstore;
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        columns: [
          {
            data: "lead_id",
            orderable: false,
          },
          {
            data: "customer",
            orderable: false,
          },
          {
            data: "agent",
            orderable: false,
          },
          {
            data: "vehicle_type",
            orderable: false,
          },
          {
            data: "inStore",
            orderable: false,
          },
          {
            data: "vehicle",
            orderable: false,
          },
          {
            data: "deal_type",
            orderable: false,
          },
          {
            data: "credit",
            orderable: false,
          },
        ],
      });

    $("#tasks_table").on("click", "tr", function () {
      var id = $(this).find(".lead_id").data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        // v.taskDetail(id);
        v.redirectIt(id);
      }
    });
  },
};
</script>

<style scoped>
.task-table .timeline,
.addcustomer-table .timeline {
  margin-top: 103px;
  position: relative;
  top: 15px;
  float: right;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  width: 330px;
  margin: 0 auto;
  width: 551px;
}
.vue-daterange-picker {
  width: 100%;
}

@import "../../assets/css/dataTables.css";
</style>
