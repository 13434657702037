import { render, staticRenderFns } from "./DeskingLog.vue?vue&type=template&id=15784128&scoped=true&"
import script from "./DeskingLog.vue?vue&type=script&lang=js&"
export * from "./DeskingLog.vue?vue&type=script&lang=js&"
import style0 from "./DeskingLog.vue?vue&type=style&index=0&id=15784128&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15784128",
  null
  
)

export default component.exports